import DataTableReportes from '../CustomComponents/DataTableReportes';
import { Card, Grid, Button, Group, Autocomplete, Box, LoadingOverlay, Modal, FileInput, Badge, Text, Textarea, Divider } from '@mantine/core';
import classes from './ReporteEmbarques.module.css';
import { useState, useEffect, useContext } from 'react';
import { AppContext } from '../../context/context';
import { useDisclosure } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import DataTableEstatus from '../CustomComponents/DataTableEstatus';
import axios from 'axios';
import * as XLSX from 'xlsx';

const ReporteEmbarques = () => {

    const appcontext = useContext(AppContext);

    const columnsToExtract = [5, 0, 1, 2, 3, 26, 27, 8, 11]; // Columns to extract (0-based index)

    const [selectedValue, setSelectedValue] = useState('');
    const [dataofTable, setDataofTable] = useState([]);
    const [dataEstatus,setDataEstatus] = useState([])
    const [visible, setIsVisible] = useState(false);
    const [clientes, setClientes] = useState(['']);
    const [opened, { open, close }] = useDisclosure(false);
    const [MailOpend, setMailOpened] = useState(false);
    const [codigo, setCodigo] = useState('');
    const [fecha, setFecha] = useState('');
    const [mailBody, setMailBody] = useState('');
    const [mailData, setMailData] = useState([]);    

    const [file, setFile] = useState(null);


    useEffect(() => {
        GetClientes();
    }, []);

    const handleChange = (value) => {
        setSelectedValue(value);
    };

    const handleOnFocus = (e) => {
        setSelectedValue('');
    }

    const handleFileUpload = (e) => {
        setFile(e);
    };

    const handleSendData = async () => {
        setIsVisible(false);
        if (file) {
            close();
            const data = await file.arrayBuffer();
            /* data is an ArrayBuffer */
            const workbook = XLSX.read(data, { type: 'array' });

            const allSheetData = workbook.SheetNames.map(sheetName => {
                const worksheet = workbook.Sheets[sheetName];

                // Convert sheet to JSON where each cell is read as a string
                const sheetData = XLSX.utils.sheet_to_json(worksheet, {
                    header: 1,        // Each row is returned as an array
                    raw: false        // Treat all cells as strings
                });

                // Convert cells to strings explicitly if needed
                return sheetData.map(row => row.map(cell => String(cell)));
            });
            const jsonData = allSheetData.flat();


            //limpiamos la informacion.
            jsonData.shift();
            jsonData.shift();
            jsonData.shift();
            var x = jsonData.map(row => columnsToExtract.map(colIndex => row[colIndex]));
            var values = {};            
            try {
                setIsVisible(true);
                values.USUARIO_ACTUALIZACION = sessionStorage.getItem("user");
                values.CODIGO = jsonData[0][19];
                values.arr_in = x;
                
                 const response = await axios.post(process.env.REACT_APP_API_URL_DE + '/api/reporteEmbarques', values, {
                     headers: {
                         Authorization: `Bearer ${sessionStorage.getItem("token")}`, // Include the Bearer token
                     }
                 });
                showNotification({
                    title: 'Listo!',
                    message: 'Archivo guardado con exito',
                    color: '#fff',
                    styles: (theme) => ({
                        root: {
                            backgroundColor: '#25A661',
                            borderColor: '#25A661',
                            '&::before': { backgroundColor: theme.white },
                        },
                        title: { color: theme.white },
                        description: { color: theme.white },
                        closeButton: {
                            color: theme.white,
                        },
                    }),
                    autoClose: false,
                })

            } catch (err) {
                showNotification({
                    title: 'Error!',
                    message: err.response?.data?.error ? err.response.data.error : err.message,
                    color: '#fff',
                    styles: (theme) => ({
                        root: {
                            backgroundColor: '#E93F19',
                            borderColor: 'E93F19',
                            '&::before': { backgroundColor: theme.white },
                        },
                        title: { color: theme.white },
                        description: { color: theme.white },
                        closeButton: {
                            color: theme.white,
                        },
                    }),
                    autoClose: false,
                })

            } finally {
                setIsVisible(false);
            }
        };
    }

    const handleGetTableData = async () => {
        try {
            setIsVisible(true);
            const response = await fetch(process.env.REACT_APP_API_URL_DE + `/api/reporteEmbarques/${selectedValue}`,{
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("token")}`, // Include the Bearer token
                }
            });
            if (!response.ok) {
                console.log(response);  
                throw new Error(response);
            }
            let result = await response.json();
            if (result.data.length == 0) {
                throw new Error("No hay datos cargados para este cliente");
            }
            // result[0].DATA_SKY.forEach(element => {
            //     var cont = 0;
            //     element["Sky info"].forEach((element2, index, arr) => {

            //         if (element2[0].includes('BKD')) {

            //             if (cont == 0) {                        
            //                 element['# Vuelo'] = element2[6];
            //                 element['ETD'] = element2[1];
            //                 element['H. Despacho'] = element2[4];
            //                 element['H. Arrivo'] = element2[5];
            //                 element['Ruta'] = element2[2];
            //                 var filtered = arr.filter(item => item.includes('DEP ' + (cont + 1)));

            //                 if (filtered.length != 0)
            //                     element['Piezas Desp'] = filtered[0][3];
            //                 else
            //                     element['Piezas Desp'] = "";

            //             } else {
            //                 element['# Vuelo' + (cont + 1)] = element2[6];
            //                 element['ETD' + (cont + 1)] = element2[1];
            //                 element['H. Despacho' + (cont + 1)] = element2[4];
            //                 element['H. Arrivo' + (cont + 1)] = element2[5];
            //                 element['Ruta' + (cont + 1)] = element2[2];
            //                 var filtered = arr.filter(item => item.includes('DEP ' + (cont + 1)));

            //                 if (filtered.length != 0)
            //                     element['Piezas Desp' + (cont + 1)] = filtered[0][3];
            //                 else
            //                     element['Piezas Desp' + (cont + 1)] = "";
            //             }
            //             cont++;
            //         }

            //     })
            //     delete element["Sky info"];
            // });            
            setDataofTable(result.data);
            setCodigo(result.codigo);
            setFecha(result.fecha);

        } catch (err) {             
            console.log(err)  
            showNotification({
                title: "Error!",
                message: err.message,
                color: '#fff',
                styles: (theme) => ({
                    root: {
                        backgroundColor: '#E93F19',
                        borderColor: 'E93F19',
                        '&::before': { backgroundColor: theme.white },
                    },
                    title: { color: theme.white },
                    description: { color: theme.white },
                    closeButton: {
                        color: theme.white,
                    },
                }),
                autoClose: false,
            })
        } finally {
            setIsVisible(false);
        }

    }

    const handleSendMail = async () => {
        try {
            setIsVisible(true);
            setMailOpened(false);
            var values = {};
            values.CODIGO = selectedValue;
            values.MailBody = mailBody;
            values.USUARIO_ACTUALIZACION = sessionStorage.getItem("user");
            const response = await axios.post(process.env.REACT_APP_API_URL_DE + '/api/reporteEmbarques/envioCorreo', values,{
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("token")}`, // Include the Bearer token
                }
            });
            showNotification({
                title: 'Listo!',
                message: 'Reporte enviado con exito',
                color: '#fff',
                styles: (theme) => ({
                    root: {
                        backgroundColor: '#25A661',
                        borderColor: '#25A661',
                        '&::before': { backgroundColor: theme.white },
                    },
                    title: { color: theme.white },
                    description: { color: theme.white },
                    closeButton: {
                        color: theme.white,
                    },
                }),
                autoClose: false,
            })

        } catch (err) {
            console.log(err);
            showNotification({
                title: 'Error!',
                message: err.response?.data?.error ? err.response.data.error : err.message,
                color: '#fff',
                styles: (theme) => ({
                    root: {
                        backgroundColor: '#E93F19',
                        borderColor: 'E93F19',
                        '&::before': { backgroundColor: theme.white },
                    },
                    title: { color: theme.white },
                    description: { color: theme.white },
                    closeButton: {
                        color: theme.white,
                    },
                }),
                autoClose: false,
            })

        } finally {
            setIsVisible(false);
        }
    }

    const handleGetEstatusTables = async () =>{
        try {
            setIsVisible(true);
            const response = await fetch(process.env.REACT_APP_API_URL_DE + `/api/reporteEmbarques/getEstatusTables/all`,{
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("token")}`, // Include the Bearer token
                }
            });
            if (!response.ok) {
                throw new Error(response.statusText);
            }
            let result = await response.json();                                             
            setDataEstatus(result);            
        } catch (err) {
            console.log(err);
            showNotification({
                title: 'Error!',
                message: err.response?.data?.error ? err.response.data.error : err.message,
                color: '#fff',
                styles: (theme) => ({
                    root: {
                        backgroundColor: '#E93F19',
                        borderColor: 'E93F19',
                        '&::before': { backgroundColor: theme.white },
                    },
                    title: { color: theme.white },
                    description: { color: theme.white },
                    closeButton: {
                        color: theme.white,
                    },
                }),
                autoClose: false,
            })
        } finally {
            setIsVisible(false);
        }
    }

    //aqui solo pintar los que estan activos y hacer la prueba
    const GetClientes = async () => {
        try {
            setIsVisible(true);            
            const response = await fetch(process.env.REACT_APP_API_URL_DE + '/api/clientes', {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
                    'Content-Type': 'application/json',
                }
            });
            if (!response.ok) {
                throw new Error(response.statusText);
            }
            let result = await response.json();
            setClientes(result.filter(item => item.ESTATUS === true).map(item => item.CODIGO))
        } catch (err) {
            showNotification({
                title: 'Error!',
                message: err.response?.data?.error ? err.response.data.error : err.message,
                color: '#fff',
                styles: (theme) => ({
                    root: {
                        backgroundColor: '#E93F19',
                        borderColor: 'E93F19',
                        '&::before': { backgroundColor: theme.white },
                    },
                    title: { color: theme.white },
                    description: { color: theme.white },
                    closeButton: {
                        color: theme.white,
                    },
                }),
                autoClose: false,
            })
        } finally {
            setIsVisible(false);
        }
    }


    return (
        <Box pos="relative">
            <LoadingOverlay
                visible={visible}
                overlayProps={{ radius: 'sm', blur: 2 }}
                loaderProps={{ color: '#FA0073', type: 'bars', size: 'xl' }}
                style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1000,
                }}
            />
            <div className={classes.main}>
                <Grid margin="md">
                    <Grid.Col span={8} offset={2}>
                        <Card shadow="sm" padding="lg" radius="md" withBorder justify="space-between">
                            <Group justify="center" mt="md">

                                <Autocomplete
                                    placeholder="Selecciona una opcion"
                                    data={clientes.map(item => item)}
                                    value={selectedValue}
                                    onChange={handleChange}
                                    onFocus={handleOnFocus}
                                />
                                <Button.Group>
                                    <Button variant="outline" color='#035CF7' onClick={open}>
                                        Cargar Reporte
                                    </Button>
                                    <Button variant="outline" color='#035CF7' onClick={handleGetTableData}>
                                        Buscar
                                    </Button><Button variant="outline" color='#035CF7' onClick={handleGetEstatusTables}>
                                        Recuperar Estatus
                                    </Button>
                                </Button.Group>

                            </Group>
                        </Card>
                    </Grid.Col>
                    {dataofTable.length === 0 ? "" : <Grid.Col>
                        <div style={{ padding: '20px' }}>
                            <DataTableReportes data={dataofTable} codigo={codigo} fecha={fecha} />
                        </div>
                    </Grid.Col>}

                    {dataEstatus.length === 0 ? "" : <Grid.Col>
                        <div style={{ padding: '20px' }}>
                            <DataTableEstatus data={dataEstatus} setIsVisible={setIsVisible} setMailData={setMailData} setMailOpened={setMailOpened} setSelectedValue={setSelectedValue}/>
                        </div>
                    </Grid.Col>}
                </Grid>
            </div>
            <Modal opened={opened} onClose={close} title="Carga reporte">
                <FileInput
                    variant="filled"
                    size="md"
                    radius="xl"
                    label="Selecciona un archivo"
                    description="Carga un archivo xlsx"
                    placeholder="Selecciona un archivo"
                    onChange={handleFileUpload}
                />
                <Group justify="flex-end" mt="md">
                    <Button type="button" variant='outline' onClick={close}>Cerrar</Button>
                    <Button type="button" onClick={handleSendData}>Enviar</Button>
                </Group>
            </Modal>
            <Modal opened={MailOpend} onClose={() => setMailOpened(false)} title="Envio de reporte">
                {
                    mailData.length != 0 ?
                        mailData.ENVIO_CORREO.map((item, i) => {
                            return (
                                <div key={i}>
                                    <Divider my="xs" label={item.NOMBRE_ARCHIVO.replace('_DDMMYYYY', '')} labelPosition="center" />
                                    <Badge color="#0d6efd" >To</Badge>
                                    <Text size="sm">{item.TO.join(',\n')}</Text>
                                    <Badge color="#6c757d">CC</Badge>
                                    <Text size="sm">{item.CC.join(',\n')}</Text>
                                    <Badge color="#0dcaf0">Nombre del archivo</Badge>
                                    <Text size="sm">{item.NOMBRE_ARCHIVO + '.xlsx'}</Text>
                                </div>
                            );
                        }) : ""
                }
                <Textarea label="Cuerpo del correo"
                    value={mailBody}
                    onChange={(event) => setMailBody(event.currentTarget.value)}
                    autosize
                    minRows={2}
                    maxRows={4}
                >
                </Textarea>
                <Group justify="flex-end" mt="md">
                    <Button type="button" variant='outline' onClick={() => setMailOpened(false)}>Cerrar</Button>
                    <Button type="button" onClick={handleSendMail}>Enviar</Button>
                </Group>
            </Modal>

        </Box>
    );

}

export default ReporteEmbarques;